const teamMember = document.querySelectorAll(".js-open-member-modal");

teamMember.forEach((member) => {
  member.addEventListener("click", () => {
    const dialog = member.nextElementSibling;
    dialog.showModal();

    const closeButton = dialog.querySelector(".js-close-member-modal");
    closeButton.addEventListener("click", () => {
      dialog.close();
    });
  });
});
